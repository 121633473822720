import { connect } from 'react-redux';
import { NavLink, NavLinkProps, RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { IMainLayoutStateProps, RootState } from '../MainLayout/types';

import messages from 'components/MainNav/messages';
import { Viewport } from 'components/ViewportProvider';
import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Store } from 'types/schema';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import media from 'utils/mediaStyle';
import { viewPermissions } from 'utils/permissionMap';
import SVGIcon from '../../components/SVGIcon/SVGIcon';
import { signout } from '../MainLayout/actions';
import reducer from '../MainLayout/reducer';
import saga from '../MainLayout/saga';
import makeSelectMainLayout, { selectCountPendingOrder, selectPendingApprovalMode } from '../MainLayout/selectors';
import ArrowDown from './ArrowDown.svg';
import DefaultAvatar from './DefaultAvatar.svg';
interface AccountMobilePageType extends RouteComponentProps {
  mainLayout: any;
  signout: () => void;
}
const AccountInfoContainer = styled.div``;
const AccountAvatar = styled.img`
  width: 36pt;
  height: 36pt;
  flex-shrink: 0;
  height: 36pt;
  border-radius: 8px;
  border: 1px solid #dce0e9;
`;
const AccountInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20pt 12px 12px;
  padding: 12pt;
  background: #ffffff;
`;
const AccountName = styled.div`
  font-weight: 500;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  > span {
    font-weight: 400;
  }
  ${media.lg`
    width: 140px;
  `};
`;

const AccountNavLabel = styled.span<any>`
  position: relative;
  transition: all 0.2s;
  position: absolute;
  span.notif {
    position: absolute;
    width: 8px;
    height: 8px;
    right: -12px;
    border-radius: 100%;
    background: #fe5f55;
  }
`;

const StyledLink = styled(NavLink)<any>`
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 16px;
  &,
  &:visited {
    color: #424242;
  }
  &.active,
  &:hover {
    background-color: rgba(66, 66, 66, 0.05);
    color: rgba(66, 66, 66, 1);
  }
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(158, 158, 158, 0.2);
  }
  span.store-name {
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
  }
`;
type ChildNavLinkProps = { opened: boolean } & NavLinkProps;
const ChildNavLink = styled(({ opened, ...rest }: ChildNavLinkProps) => <NavLink {...rest} />)<ChildNavLinkProps>`
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  align-items: center;
  padding: 10pt 10pt 10pt 14pt;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  height: 58px;
  &.active,
  &:hover {
    color: #2f904f;
    .icon {
      display: flex;
    }
  }
`;
const AccountNavItemWrapper = styled.div`
  margin-top: 19px;
  background: #ffffff;
`;
const AccountNavWrapper = styled.nav`
  white-space: nowrap;
  width: 100%;
  ${AccountInfoContainer} {
    flex-direction: row;
  }
  ${AccountAvatar} {
    margin-left: 0;
  }
  transform: translate3d(0, 0, 0);
  ${AccountInfoContainer} {
    flex-direction: row;
  }
  ${AccountAvatar} {
    margin-left: 0;
  }
  ${StyledLink} {
    padding: 20pt 12pt 16pt;
  }
  ${ChildNavLink} {
    display: flex;
  }
`;
const AccountNavItem = (props: any) => {
  const { children, favorite, ...rest } = props;
  return (
    <StyledLink favorite={favorite ? 'true' : 'false'} {...rest} exact={true}>
      {props.children}
    </StyledLink>
  );
};
const AccountNavItemWithChildren = styled.div<any>`
  transition: all 0.3s ease-in;
  white-space: nowrap;
  &,
  &:visited {
    border-left-color: transparent;
    color: #424242;
  }
  a {
    background-color: ${(props) => props.isActive && 'rgba(66, 66, 66, 0.05)'};
    color: ${(props) => props.isActive && 'rgba(66, 66, 66, 1)'};
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgba(158, 158, 158, 0.2);
  }
`;

const AccountNavContainer = styled.div`
  @media (max-width: 767px) {
    margin-top: -5px;
  }
`;

const AccountTextBox = styled.div`
  & > a {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 4px 0 0 !important;
    span {
      font-weight: 400;
    }
    & > div {
      margin-left: 5px;
    }
  }
`;

const SignOut = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 20pt 12pt 16pt;
  &,
  &:visited {
    color: #424242;
  }
  &.active,
  &:hover {
    background-color: rgba(66, 66, 66, 0.05);
    color: rgba(66, 66, 66, 1);
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgba(158, 158, 158, 0.2);
  }
  & span {
    color: #ef5350;
  }
`;
interface IStoreProps {
  user: any;
  store: any;
}
const Store: FC<IStoreProps> = (props) => {
  const { user, store } = props;
  const alternativeImage = (name) => `https://ui-avatars.com/api/?name=${name}&size=512`;
  const avatarSource = user.imageUrl || alternativeImage(user.name) || DefaultAvatar;
  return (
    <AccountInfoContainer>
      <AccountInfoWrapper>
        <AccountAvatar src={avatarSource} />
        <AccountTextBox>
          <AccountName>{user.name}</AccountName>
          <AccountNavItem to="/change-store">
            <span>
              <FormattedMessage {...messages.storeInfo} />
            </span>
            <span className="store-name">{store.name}</span>
            <SVGIcon
              renderAsImg={false}
              src={ArrowDown}
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 10px');
              }}
            />
          </AccountNavItem>
        </AccountTextBox>
      </AccountInfoWrapper>
    </AccountInfoContainer>
  );
};

const AccountMobilePage: FC<AccountMobilePageType> = (props) => {
  const {
    location,
    mainLayout: { user, store },
    signout,
  } = props;

  const userPermissions = useMemo(() => {
    const permissions = (user.permissions || []).map((permission) => permission.action);
    (user.userGroups || []).forEach((group) => {
      group.permissions.forEach((permission) => {
        if (permissions.indexOf(permission.action) === -1) {
          permissions.push(permission.action);
        }
      });
    });
    return permissions;
  }, [user.permissions, user.userGroups]);
  const canAccessView = (path: string): boolean => {
    const permissions: string[] = viewPermissions[path];
    if (permissions) {
      let permissionCounter = 0;
      for (const permission of permissions) {
        if (userPermissions.indexOf(permission) > -1) {
          permissionCounter += 1;
        }
      }
      if (permissionCounter !== permissions.length) {
        return false;
      }
    }
    return true;
  };
  const path = location.pathname;

  return (
    <Viewport.Consumer>
      {({ width }) => {
        const isMobile = width < 768;
        return (
          <>
            {width < 1025 && (
              <AccountNavContainer>
                <AccountNavWrapper>
                  <Store user={user} store={store} />
                  <AccountNavItemWrapper>
                    <AccountNavItem to="/account-info">
                      <AccountNavLabel>
                        <FormattedMessage {...messages.accountSetting} />
                      </AccountNavLabel>
                    </AccountNavItem>
                    {canAccessView('/orders') && (
                      <AccountNavItemWithChildren
                        isActive={['/orders', '/orders-pending-approval', '/pending-orders-history'].includes(path)}
                      >
                        <AccountNavItem to="/orders">
                          <AccountNavLabel>
                            <FormattedMessage {...messages.orderHistory} />
                          </AccountNavLabel>
                        </AccountNavItem>
                      </AccountNavItemWithChildren>
                    )}
                    <AccountNavItem to="/company-info">
                      <AccountNavLabel>
                        <FormattedMessage {...messages.companyInfo} />
                      </AccountNavLabel>
                    </AccountNavItem>
                    <AccountNavItem to="/store-info">
                      <AccountNavLabel>
                        <FormattedMessage {...messages.storeInfo} />
                      </AccountNavLabel>
                    </AccountNavItem>
                    {!isMobile && (canAccessView('/user-management') || canAccessView('/group-management')) && (
                      <AccountNavItemWithChildren
                        isActive={path === '/user-management' || path === '/group-management'}
                      >
                        <AccountNavItem to="/user-management">
                          <AccountNavLabel>
                            <FormattedMessage {...messages.usersAndGroup} />
                          </AccountNavLabel>
                        </AccountNavItem>
                      </AccountNavItemWithChildren>
                    )}
                    <AccountNavItem to="/overview">
                      <AccountNavLabel>
                        <FormattedMessage {...messages.overview} />
                      </AccountNavLabel>
                    </AccountNavItem>
                    <SignOut onClick={signout}>
                      <AccountNavLabel>
                        <FormattedMessage {...messages.signOut} />
                      </AccountNavLabel>
                    </SignOut>
                  </AccountNavItemWrapper>
                </AccountNavWrapper>
              </AccountNavContainer>
            )}
          </>
        );
      }}
    </Viewport.Consumer>
  );
};

const mapStateToProps = createStructuredSelector<RootState, IMainLayoutStateProps>({
  mainLayout: makeSelectMainLayout(),
  isPenddingApproval: selectPendingApprovalMode(),
  countPendingOrder: selectCountPendingOrder(),
});

const mapDispatchToProps = (dispatch: Dispatch): { signout: () => void } => {
  return {
    signout: () => dispatch(signout()),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'main', reducer: reducer });
const withSaga = injectSaga({ key: 'main', saga: saga });

export default compose<React.ComponentType<any>>(withReducer, withSaga, withConnect, withRouter)(AccountMobilePage);
